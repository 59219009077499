<template>
	<div class="page">

		<!-- 搜索条件表单 -->
		<el-form class="ser_form" size="small"  label-width="90px">
			<el-form-item class="el_form_item" label="流水编号">
				<el-input class="el_input" v-model="form.ra_num" placeholder="流水编号搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="运单编号">
				<el-input class="el_input" v-model="form.truck_tord_num" placeholder="运单编号搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="货主手机号">
				<el-input class="el_input" v-model="form.shipper_tel" placeholder="货主手机号搜索" clearable></el-input>
			</el-form-item>
			<!-- <el-form-item class="el_form_item" label="车主手机号">
				<el-input class="el_input" v-model="form.truck_owner_tel" placeholder="车主手机号搜索" clearable></el-input>
			</el-form-item> -->
			<el-form-item class="el_form_item" label="司机手机号">
				<el-input class="el_input" v-model="form.driver_tel" placeholder="司机手机号搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="支付类型">
				<el-select class="el_input" v-model="form.this_pay_type" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="现金" value="1"></el-option>
					<el-option label="燃油费" value="2"></el-option>
					<el-option label="过路费" value="3"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="开票状态">
				<el-select class="el_input" v-model="form.invoice_status" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="未申请" value="1"></el-option>
					<el-option label="申请中" value="2"></el-option>
					<el-option label="已开出" value="3"></el-option>
					<el-option label="已驳回" value="4"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="结算状态">
				<el-select class="el_input" v-model="form.settle_status" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="待打款" value="2"></el-option>
					<el-option label="已打款" value="3"></el-option>
					<el-option label="结算失败" value="4"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="排序类型">
				<el-select class="el_input" v-model="form.group_num_type" clearable>
					<el-option label="默认排序" value="1"></el-option>
					<el-option label="账单排序" value="2"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="备注">
				<el-input class="el_input" v-model="form.mark" placeholder="备注搜索" clearable></el-input>
			</el-form-item>
			<el-form-item label-width="0">
				<el-button type="primary" @click="cargo_ser">查询</el-button>
				<el-button type="primary" @click="ser_para_clear">清空条件</el-button>
				<!-- <el-button type="primary" >查看轨迹</el-button> -->
			</el-form-item>
		</el-form>
		<!-- 表格 -->
		<div class="tab_height">
			<el-table :data="list.data" :border="true" :stripe="true" size="small"  v-loading="list.loading">
				<el-table-column label="收款银行卡号" width="210px">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.bank_card_num}}</div>
					</template>
				</el-table-column>
				<el-table-column label="收款人姓名" width="210px">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.payee_info.name}}</div>
					</template>
				</el-table-column>
				<el-table-column label="收款金额" width="210px">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.this_settled_total}}</div>
					</template>
				</el-table-column>
				<el-table-column label="运单列表" width="210px">
					<template slot-scope="scope">
						<el-button type="primary" @click="show_orders(scope.row)">查看运单</el-button>
					</template>
				</el-table-column>
				<el-table-column label="支付记录" width="210px">
					<template slot-scope="scope">
						<div class="tab_line_item">支付记录</div>
					</template>
				</el-table-column>
				<el-table-column label="操作">
					<template slot-scope="scope">
						<el-button type="primary" @click="wy_pay(scope.row)">网银汇款</el-button>
						<el-button type="primary" @click="kq_pay(scope.row)">KQ支付</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
	</div>
</template>

<script>
	import cashSettle from './tord_settle/cash_settle.vue'
	import fuelCostSettle from './tord_settle/fuel_cost_settle.vue'
	import roadTollSettle from './tord_settle/road_toll_settle.vue'
	export default {
		components:{
			cashSettle,
			fuelCostSettle,
			roadTollSettle,
		},
		data() {
			return {

				//搜索条件
				form: {
					ra_num:'',//货源编号
					truck_cargo_num:'',//货源编号
					shipper_tel:'',//货主手机号
					truck_owner_tel:'',//车主手机号
					driver_tel:'',//司机手机号
					this_pay_type:'',//本次支付类型(1:现金,2:燃油费,3:过路费)
					pay_status:'',//支付状态(1:待审核,2:支付完成,3:支付失败/打回)
					invoice_status:'',//开票状态(1:未申请,2:申请中,3:已开出,4:已驳回)
					settle_status:'',//结算给车主的状态(1:未申请,2:待打款,3:已打款,4:结算失败)
					group_num_type :'1',//排序类型(1:默认排序,2:账单排序)
					mark:'',//备注
				},

				//表格数据
				list: {
					loading: false,//loading特效
					data: [],//列表数据
					total: 0,//列表总记录数
				},

				//页码信息
				page: {
					need_num:1,
					num: 10,
					p: 1,
				},

				//现金结算页面
				cash_settle:{
					is_show:0,//是否显示
					tord_payed_info:{},//支付记录
				},

				//燃油费结算页面
				fuel_cost_settle:{
					is_show:0,//是否显示
					tord_payed_info:{},//支付记录
				},

				//过路费结算页面
				road_toll_settle:{
					is_show:0,//是否显示
					tord_payed_info:{},//支付记录
				},
			}
		},
		created() {

			//读取货源
			this.get_page_data()
		},
		methods: {
			//网银汇款
			wy_pay(info){
				this.$my.net.req({
					data:{
						mod:'truck_tord_real_time',
						ctr:'settle_done_admin_new',
						payed_ids:info.payed_ids,
						platform_bank_cname:"安庆农村商业银行城东支行",
						platform_card_num:"20010213563966600000018",
						user_card_num:info.bank_card_num
					},
					callback:(data)=>{

					},
				})
			},
			//快钱支付
			kq_pay(info){
				this.$my.net.req({
					data:{
						mod:'truck_tord_real_time',
						ctr:'settle_done_admin_kq',
						ids:info.payed_ids
					},
					callback:(data)=>{

					},
				})
			},
			//查看快钱余额
			kq_balance(info){

			},
			show_orders(info){

			},
			//清空查询条件
			ser_para_clear(){
				this.form={
					ra_num:'',//货源编号
					truck_cargo_num:'',//货源编号
					shipper_tel:'',//货主手机号
					truck_owner_tel:'',//车主手机号
					driver_tel:'',//司机手机号
					this_pay_type:'',//本次支付类型(1:现金,2:燃油费,3:过路费)
					pay_status:'',//支付状态(1:待审核,2:支付完成,3:支付失败/打回)
					invoice_status:'',//开票状态(1:未申请,2:申请中,3:已开出,4:已驳回)
					settle_status:'',//结算给车主的状态(1:未申请,2:待打款,3:已打款,4:结算失败)
					group_num_type :'1',//排序类型(1:默认排序,2:账单排序)
					mark:'',//备注
				}
				this.get_page_data()
			},

			//搜索
			cargo_ser(){
				this.get_page_data()
			},

			//页宽变化
			page_size_change(num){

				//置入页码
				this.page.num=num

				//读取
				this.get_page_data()
			},

			//页码变化
			page_current_change(p){

				//置入页码
				this.page.p=p

				//读取
				this.get_page_data()
			},

			//获取货源列表
			get_page_data(){

				//加载中...
				// if(this.list.loading){
				// 	return;
				// }

				//置为加载中
				this.list.loading=true;

				// if(!this.form.this_pay_type){
				// 	this.form.this_pay_type=["not in","(2)"];
				// }

				//读取数据
				this.$my.net.req({
					data:{
						mod:'truck_tord_real_time',
						ctr:'tord_payed_list_by_admin_new',
						"settle_status":2,
						...this.form,
						...this.page,
					},
					callback:(data)=>{
						//加载完毕
						this.list.loading=false
						//总数
						this.list.total=data.max

						//渲染
						this.list.data=data.list
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	.page{
		background-color: #fff;
		padding: 20px;
		border-radius: 10px;
		height: calc(100% - 40px);
		overflow: auto;
	}
	.ser_form{
		display: flex;
		align-items: flex-end;
		flex-wrap: wrap;
	}
	.tab_height{
		height:calc(100% - 134px);
	}
	.el_form_item{
		width: 275px;
	}
	.el_input{
		width:94%
	}
	.btn_left{
		margin: 0;
	}
</style>